<template>
  <div class="home">
    <div v-if="showPage==1">
      <div class="title">
        订单支付
      </div>
      <div class="user mb-20">
        <div class="user-title">用户信息</div>
        <div class="user-name">{{ buyerName || "暂无" }}</div>
        <div class="user-phone">{{ buyerPhone || "暂无" }}</div>
      </div>
      <div class="user mb-30">
        <div class="user-title">订单信息</div>
        <div class="order">
          <div class="order-num">订单编号：{{ orderNo || "暂无" }}</div>
          <div class="copy" @click="copyText">复制</div>
        </div>
        <div class="order-time">下单时间：{{ createTime || "暂无" }}</div>
        <div class="goods-wrap">
          <div class="goods" v-for="(item,i) in goodsList" :key="i">
            <div class="goods-name">{{ item.goodsName }}</div>
            <div class="goods-info">
              <div class="price">￥{{ item.params.salePrice }}</div>
              <div class="text-right goods-num">x{{ item.goodsNumber }}</div>
            </div>
          </div>
          <div class="give" v-for="(item,index) in giftDetails" :key="'index-'+index">
            <div style="display: flex;">
              <div class="give-title">赠品</div>
              <div class="give-name">{{ item.goodsName }}</div>
            </div>
            <div class="goods-info">
              <div class="give-price">￥{{ item.params.salePrice }}</div>
              <div class="text-right give-num">x{{ item.goodsNumber }}</div>
            </div>
          </div>
          <div class="warranty" v-for="(item ,index) in warrantyInfo" :key="'index_' + index">
            <div style="display: flex;">
              <div class="give-title">保障服务</div>
              <div class="give-name">{{ item.goodsName }}</div>
            </div>
            <div class="goods-info">
              <div class="give-price">￥{{ item.params.salePrice }}</div>
              <div class="text-right give-num">x{{ item.goodsNumber }}</div>
            </div>

          </div>
          <div class="overallSum">
            <div style="color: #aaa;margin-right: 10px;font-size: 14px">共{{ orderNumber }}件</div>
            <div><b>实付金额:</b><span style="color: #D9001B;margin-left: 5px">￥{{ orderMoney }}</span></div>
          </div>
        </div>
      </div>
      <van-button color="#FF745B" block @click="handlePay">立即支付</van-button>
      <p style="display: flex;justify-content: center;align-items: center;color: #666666;font-weight: 400;">页面来源: <img
        style="margin-left:5px" src="../assets/logo.svg" alt="">看牙帮</p>
    </div>
    <div v-if="showPage==2">
      <div style="padding-top: 70px">
        <img src="https://img1.hisforce.cc/sb%402x.png" width="64" height="64" style="margin-bottom: 10px" />
        <h3>支付失败</h3>
        <p style="color: #FF2700;font-size:10px">{{ errMsg }}</p>
        <p style="color: #FF2700;font-size:10px">温馨提示：请返回看牙帮小程序【我的】个人中心-待付款页面重新支付</p>
      </div>
    </div>
    <!--    <van-skeleton title :row="3" v-if="showPage==0"/>-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      buyerName: "",
      buyerPhone: "",
      orderNo: "",
      createTime: "",
      goodsList: [],
      giftDetails: [],
      showPage: 0,
      token: "",
      errMsg: "",
      broType: "",
      orderNumber: "",
      orderMoney: ""
    };
  },
  created() {
    this.token = this.$route.params?.type || '001';
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("applewebkit") > -1 && ua.indexOf("mobile") > -1 && ua.indexOf("safari") > -1 &&
      ua.indexOf("linux") === -1 && ua.indexOf("android") === -1 && ua.indexOf("chrome") === -1 &&
      ua.indexOf("ios") === -1 && ua.indexOf("browser") === -1) {
      this.broType = "safari";
    }
    axios.get(`${process.env.VUE_APP_EXTERNAL_LINK}cashier/trade/query/${this.token}`).then(res => {
      if (res.data.code == 500) {
        this.errMsg = res.data.msg;
        this.showPage = 2;
      } else {
        this.showPage = 1;
        this.buyerName = res.data.data.buyerName;
        this.buyerPhone = res.data.data.buyerMobile;
        this.orderNo = res.data.data.orderNo;
        this.createTime = res.data.data.createTime;
        this.goodsList = res.data.data.masterDetails;
        this.giftDetails = res.data.data.giftDetails;
        this.orderNumber = res.data.data.orderNumber;
        this.orderMoney = res.data.data.orderMoney;
        this.warrantyInfo = res.data.data.insuranceDetails || [];
      }
    });
  },
  methods: {
    copyText() {
      var copyInput = document.createElement("input");
      copyInput.setAttribute("value", this.orderNo);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          alert("复制成功");
        }
      } catch {
        alert("复制失败，请检查浏览器兼容");
      }
    },
    handlePay() {
      let a = new FormData();
      a.append("orderNo", this.orderNo);
      a.append("token", this.token);
      a.append("broType", this.broType);
      axios.post(`${process.env.VUE_APP_EXTERNAL_LINK}cashier/pay/ali`, a).then(res => {
        if (res.data.code == 500) {
          this.errMsg = res.data.msg;
          this.showPage = 2;
        } else {
          if (this.broType == "safari") {//苹果浏览器直接跳url
            window.location.href = res.data.data;
          } else {
            document.querySelector("body").innerHTML = res.data.data;
            const div = document.createElement("div"); // 创建div
            // div.innerHTML = res // 将返回的form 放入div
            document.body.appendChild(div);
            document.forms[0].submit();
          }
        }

      });
    }
  }
};
</script>

<style scoped>
.text-right {
  text-align: right;
}

.title {
  font-size: 16px;
  color: #333333;
  text-align: center;
  background: #ffffff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-bottom: 15px;
  font-weight: 500;
}

.user {
  padding: 15px 10px;
  background: #ffffff;
  border-radius: 10px;
  text-align: left;
}

.user-title {
  font-weight: 500;
  color: #000000;
  font-size: 16px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #EFF0F1;
}

user-name {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 5px;
}

.user-phone {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.order {
  display: flex;
  align-items: center;
}

.order-num {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  margin-right: 10px;
}

.copy {
  background: #F7F8F9;
  border-radius: 2px;
  font-size: 10px;
  width: 30px;
  height: 16px;
  text-align: center;
  font-weight: 400;
  color: #5BA7FF;
}

.order-time {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  padding-top: 5px;
}

.goods {
  margin-bottom: 10px;
}

.goods, .give {
  display: flex;
  /*align-items: center;*/
  justify-content: space-between;
}

.goods-name {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  width: 210px;
}

.price {
  font-weight: 500;
  color: #FF745B;
  font-size: 14px;
}

.goods-num {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.give-title {
  color: #FF745B;
  font-size: 12px;
}

.give-name {
  color: #666666;
  font-size: 12px;
  font-weight: 500;
  width: 210px;
}

.give-price {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.give-num {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}

.goods-wrap {
  padding-bottom: 15px;
  border-bottom: 1px solid #EFF0F1;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-20 {
  margin-bottom: 15px;
}

.van-button {
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.overallSum {
  padding: 5px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
}
</style>
